@font-face {
  font-family: "Poppins";
  /* src: url("/fonts/Poppins-Thin.otf"); */
  src: url("https://res-front.pumpsoul.com/font/Poppins-Thin.otf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  /* src: url("/fonts/OpenSans-Regular.ttf"); */
  src: url("https://res-front.pumpsoul.com/font/OpenSans-Regular.ttf")
    format("truetype");
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  /* src: url("/fonts/OpenSans-Medium.ttf"); */
  src: url("https://res-front.pumpsoul.com/font/OpenSans-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  /* src: url("/fonts/OpenSans-Bold.ttf"); */
  src: url("https://res-front.pumpsoul.com/font/OpenSans-Bold.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Caveat";
  /* src: url("/fonts/Caveat-Regular.ttf"); */
  src: url("https://res-front.pumpsoul.com/font/Caveat-Regular.ttf")
    format("truetype");
  font-weight: regular;
  font-style: normal;
  font-display: swap;
}
